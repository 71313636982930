export default {
    cluster_contracts: { route: '/accounting/cluster/contracts', version: 'v1' },
    cluster_contracts_contract: { route: '/accounting/cluster/contracts/{id}', version: 'v1' },
    cluster_contracts_contract_activate_two_factor: { route: '/accounting/cluster/contracts/{id}/actions/activate-two-factor', version: 'v2' },
    cluster_contracts_contract_deactivate_two_factor: { route: '/accounting/cluster/contracts/{id}/actions/deactivate-two-factor', version: 'v2' },
    cluster_contract_termination_dates: { route: '/accounting/cluster/contracts/{id}/data/termination_dates', version: 'v1'},
    cluster_contract_termination_download: {route: '/accounting/cluster/contracts/{id}/files/termination_form', version: 'v1'},
    domain_contracts: { route: '/accounting/domain/contracts', version: 'v1' },
    domain_contracts_contract: { route: '/accounting/domain/contracts/{id}', version: 'v1' },
    domain_contract_termination: { route: '/accounting/domain/contracts/{id}/actions/cancel', version: 'v1'},
    // Partner area
    partners_mine: { route: '/accounting/partners/mine', version: 'v1' },
    partners_sales_material: { route: '/accounting/partners/sales_material', version: 'v1' },
    partners_sales_material_download: { route: '/accounting/partners/sales_material/{lang}/{basename}', version: 'v1' },
    partners_partner_contract: { route: '/accounting/partners/{partnerId}/partnerships/{partnershipId}/files/pdf', version: 'v1' },
    partners_levels: { route: '/accounting/partners/levels', version: 'v1' },
    partners_provisions: { route: '/accounting/partners/{partnerId}/provisions', version: 'v1' },
    partners_expected_revenue: { route: '/accounting/partners/{partnerId}/expected_revenue', version: 'v1' },
    // user
    administrator_and_managers: {route: '/accounting/customers/{customerId}/administrators_and_managers', version: 'v1'},
    // orders
    cluster_orders: {route: '/accounting/cluster/orders', version: 'v1'},
    domain_orders: {route: '/accounting/domain/orders', version: 'v1'},
    certificate_orders: {route: '/accounting/certificate/orders', version: 'v1'},
    // order details
    cluster_orders_detail: {route: '/accounting/cluster/orders/{orderId}', version: 'v1'},
    cluster_orders_detail_pdf: {route: '/accounting/cluster/orders/{orderId}/pdf', version: 'v1'},
    domain_orders_detail: {route: '/accounting/domain/orders/{orderId}', version: 'v1'},
    certificate_orders_detail: {route: '/accounting/certificate/orders/{orderId}', version: 'v1'},
    certificate_orders_detail_pdf: {route: '/accounting/certificate/orders/{orderId}/pdf', version: 'v1'},
    certificate_orders_detail_certificate: {route: '/accounting/certificate/orders/{orderId}/csr-and-private-key', version: 'v1'},
    certificate_orders_detail_dcv: {route: '/accounting/certificate/orders/{orderId}/dcv', version: 'v1'},

    customers_payments: {route: '/accounting/customers/payments', version: 'v1'},
    customers_customer_payments_actions_generate_credit_card_token: {route: '/accounting/customers/{customerId}/payments/actions/generate_credit_card_token', version: 'v1'},
    customers_customer_confirm_data_processing_agreement: {route: '/accounting/customers/{customerId}/actions/confirm-data-processing-agreement', version: 'v2'},
    customers_payments_actions_generate_credit_card_token: {route: '/customers/payments/actions/generate_credit_card_token', version: 'v1'},
};