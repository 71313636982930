<template>
    <MoleculeModal :close-text="$t('helper.actions.abort')" @close="$emit('close')" v-model="display" size="lg">
        <template #modal-headline>{{ $t('profile.pat.title') }}</template>
        <template #content>
            <MoleculeLoader v-if="actionIsRunning" size="md" />
            <MoleculeForm @submit.prevent="submit" v-else>
                <div class="row pat-form">
                    <div class="col-12">
                        <AtomInput :animated-label="false" :label="$t('profile.pat.form.name')" v-model="form.name" :error="v$.form.name.$error">
                            <template #error-message>{{ $t(v$.form.name.$errors[0].$message) }}</template>
                        </AtomInput>
                    </div>
                    <div class="col-6 mt-4">
                        <strong>{{ $t('profile.pat.form.access_areas') }}</strong><br>
                        <AtomRadio
                            v-model="accessModel"
                            :options="[{text: $t('profile.pat.form.full_access'), value: 'full_access'}]"
                        />
                        <ul>
                            <li>{{ $t('profile.pat.form.all_cluster') }}</li>
                            <li>{{ $t('profile.pat.form.profile_data') }}</li>
                            <li>{{ $t('profile.pat.form.invoices') }}</li>
                        </ul>
                        <AtomRadio
                            v-model="accessModel"
                            :options="[{text: $t('profile.pat.form.limited_api_access'), value: 'limited_access'}]"
                        />
                        <span>{{ $t('profile.pat.form.access_to_cluster') }}</span>
                        <div class="d-flex stacked-checkboxes">
                            <AtomErrorLabel v-if="v$.clusterModel.$error">
                                {{ $t(v$.clusterModel.$errors[0].$message, {min: 1}) }}
                            </AtomErrorLabel>
                            <AtomCheckbox :disabled="accessModel === 'full_access'" stacked :options="clusterOptions" v-model="clusterModel" />
                        </div>
                        <AtomButton :disabled="accessModel === 'full_access'" @click.prevent="selectAll" variant="secondary">{{ $t('profile.pat.form.select_all') }}</AtomButton>
                    </div>
                    <div class="col-6 mt-4">
                        <strong>{{ $t('profile.pat.form.validity') }}</strong><br>
                        <AtomRadio
                            v-model="expiryModel"
                            :options="[{text: $t('profile.pat.unlimited'), value: 'unlimited'}, {text: $t('profile.pat.form.until'), value: 'until'}]"
                        />
                        <AtomDateTimePicker
                            v-model="form.expires_at"
                            :disabled="expiryModel === 'unlimited'"
                            :error="v$.form.expires_at.$error"
                            :min-date="new Date()"
                            :locale="$i18n.locale"
                            :enable-time-picker="false"
                            :dark="user?.darkMode || false"
                        >
                            <template #error-message>{{ $t(v$.form.expires_at.$errors[0].$message) }}</template>
                        </AtomDateTimePicker>
                    </div>
                </div>
            </MoleculeForm>
        </template>
        <template #actions>
            <AtomButton @click.prevent="submit" v-if="!token" :disabled="actionIsRunning">
                {{ $t('helper.actions.create') }}
            </AtomButton>
        </template>
    </MoleculeModal>
    <MoleculeModal primary-close :close-text="$t('helper.actions.close')" v-model="showToken" @close="showToken = false">
        <template #modal-headline>{{ $t('profile.pat.title') }}</template>
        <div class="row mt-4" v-if="token">
            <div class="col-12">
                <p>{{ $t('profile.pat.info') }}</p>
                <AtomInput :animated-label="false" disabled v-model="token.token" append @click.prevent="copy(token.token, $t('profile.pat.copied'))" dark>
                    <template #input-append>
                        <AtomButton>
                            <AtomIcon name="copy-line" />
                        </AtomButton>
                    </template>
                </AtomInput>
            </div>
        </div>
    </MoleculeModal>
</template>
<script>
    import BaseComponent from "@/components/BaseComponent";
    import {UserService, ClusterService, ResponseService} from "@development/cluster-center";
    import {required, requiredIf} from '@development/cluster-center/src/utils/validators';
    import {minLength} from "@vuelidate/validators";
    import { userStore } from "@development/cluster-center";

    export default {
        emits: ['close', 'updated:token'],
        extends: BaseComponent,
        name: 'CreatePatForm',
        props: {
            show: Boolean,
        },
        data() {
            return {
                display: false,
                form: {
                    name: null,
                    expires: 0,
                    expires_at: null,
                    abilities: {global: true},
                },
                clusters: [],
                accessModel: 'full_access',
                clusterModel: [],
                expiryModel: 'unlimited',
                token: null,
                showToken: false,
                user: null
            };
        },
        computed: {
            clusterOptions() {
                return this.clusters.map(c => {
                    return {
                        text: `${c.number} - ${c.name}`,
                        value: c.id
                    };
                });
            }
        },
        mounted() {
            let date = new Date();
            let month = date.getMonth();
            month++;

            if (month > 12) {
                month = 1;
            }

            date.setMonth(month);

            this.form.expires_at = date;

            this.prepare();

            this.user = userStore();
        },
        methods: {
            reset() {
                this.form = {
                    name: null,
                    expires: 0,
                    expires_at: null,
                    abilities: {global: true},
                };
                this.accessModel = 'full_access';
                this.clusterModel = [];
                this.expiryModel = 'unlimited';
                this.resetValidation();
            },
            prepare() {
                this.reset();
                this.display = this.show;

                if (this.show) {
                    this.token = null;
                    this.getClusters();
                }
            },
            async getClusters() {
                return await ClusterService.index({active: 1, per_page: 100}).then(response => {
                    this.clusters = response.data;
                });
            },
            async submit() {
                if (!await this.validate()) {
                    return;
                }

                let params = JSON.parse(JSON.stringify(this.form));

                if (this.expiryModel !== 'unlimited') {
                    let [day, month, year] = this.$i18n.d(params.expires_at, 'short', 'de').split('.');
                    params.expires_at = `${year}-${month}-${day}`;
                } else {
                    params.expires_at = null;
                }

                this.actionIsRunning = true;

                return await UserService.createPersonalAccessTokens(params).then(response => {
                    this.actionIsRunning = false;

                    if (Array.isArray(response)) {
                        ResponseService.globalNotify(this.$i18n.t('errors.undefined', {phone: this.$cc.support.phone_formatted, email: this.$cc.support.email }), 'danger');
                        return;
                    }

                    ResponseService.globalNotify(this.$i18n.t('profile.pat.form.success'), 'success');
                    this.token = response.data;
                    this.showToken = true;
                    this.display = false;
                    this.$emit('updated:token');
                });
            },
            selectAll() {
                this.clusterModel = this.clusterOptions.map(c => c.value);
            },
        },
        watch: {
            show() {
                this.prepare();
            },
            accessModel(val) {
                if (val === 'full_access') {
                    this.form.abilities = {global: true};
                    return;
                }

                this.form.abilities = {clusters: this.clusterModel};
            },
            clusterModel(val) {
                if (val.length > 0) {
                    this.form.abilities.clusters = val;
                }
            },
            expiryModel(val) {
                if (val === 'unlimited') {
                    this.form.expires_at = null;
                    this.form.expires = 0;

                    return;
                }

                this.form.expires = 1;
            },
        },
        validations() {
            return {
                form: {
                    name: {required},
                    expires_at: {
                        requiredIfExpires: requiredIf(() => {
                            return this.form.expires;
                        })
                    }
                },
                clusterModel: {
                    minimumOneCluster: minLength(1),
                    requiredIfLimitedAccess: requiredIf(() => {
                        return this.accessModel === 'limited_access';
                    })
                }
            };
        }
    };
</script>
<style lang="scss">
    .pat-form {
        div[role=radiogroup] .form-check {
            width: 100%;
            margin-top: .5rem;
            margin-bottom: .5rem;

            .form-check-label {
                color: $gray-900;
                font-weight: bold;
                font-size: 1rem;
            }
        }
    }

    .stacked-checkboxes {
        margin-top: .75rem;
        position: relative;

        .error-label-wrapper {
            position: absolute;
            top: -50%;
        }

        div[role=group] .form-check {
            width: 100%;
            display: inline-flex;
            justify-content: flex-start;
            align-items: flex-start;
            margin-bottom: .75rem;
        }
    }
</style>