<template>
    <div class="bg-placement row d-flex pt-3" :class="isInDemoMode === false ? 'always-visible' : ''" v-bind="$attrs">
        <div class="col-7 d-flex align-items-center">
            <div class="row support-row">
                <div class="col">
                    <strong class="support-notify"> Support (24/7/365)</strong>
                </div>
                <div class="col">
                    <AtomTextLink :href="`tel:${$cc.support.phone}`">
                        <AtomIcon name="phone-handset-line" /> {{ $cc.support.phone_formatted }} (24/7)
                    </AtomTextLink>
                </div>
                <div class="col">
                    <AtomTextLink :href="`mailto:${$cc.support.email}`">
                        <AtomIcon name="envelope-line" /> {{ $cc.support.email }} (08:00-22:00 Uhr)
                    </AtomTextLink>
                </div>
            </div>
        </div>
        <div class="col-5 lang-select d-flex justify-content-end">
            <SwitchDesign v-if="!isInDemoMode && userStore.isLoggedIn" class="me-2" />
            <AtomFilter :empty-result="false" @update:filters="changeLocale" :filters="languages" :single="true" v-model="selectedLanguage" />
        </div>
        <div class="col-12">
            <hr class="nav-divider mb-1" />
        </div>
    </div>
    <div :class="(userStore.isLoggedIn === true && !isInDemoMode) ? '' : 'd-none'" class="sticky-top mb-4 nav-wrapper">
        <div :class="userStore.isLoggedIn ? 'context-container-nav' : 'bg-placement'" class="ms-0 me-0 row mb-3 autohide">
            <div class="col-12">
                <nav class="navbar navbar-expand-lg">
                    <div class="container-fluid px-0">
                        <router-link to="/" class="navbar-brand me-4">
                            <template v-if="iaDarkMode===true">
                                <AtomImage class="d-flex align-items-center" :size="174" :src="require('@/assets/images/logo/maxcluster-white.svg')" alt="maxcluster-logo" draggable="false" />
                            </template>
                            <template v-else>
                                <AtomImage class="d-flex align-items-center" :size="174" :src="require('@/assets/images/logo/logo.svg')" alt="maxcluster-logo" draggable="false" />
                            </template>
                        </router-link>
                        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#main-nav" aria-controls="main-nav" aria-expanded="false" aria-label="Toggle navigation">
                            <span class="navbar-toggler-icon"></span>
                        </button>
                        <template v-if="userStore.isLoggedIn && !isInDemoMode">
                            <div class="collapse navbar-collapse" id="main-nav">
                                <ul class="navbar-nav d-flex align-items-center">
                                    <li class="nav-item">
                                        <AtomNavigationLink class="nav-link" :to="{ name: 'dashboard' }">{{ $t('app.navigation.clusters') }}</AtomNavigationLink>
                                    </li>
                                    <li class="nav-item">
                                        <AtomNavigationLink class="nav-link" :to="{ name: 'contracts' }">{{ $t('app.navigation.contracts') }}</AtomNavigationLink>
                                    </li>
                                    <li class="nav-item">
                                        <AtomNavigationLink class="nav-link" :to="{ name: 'invoices-overview' }">{{ $t('app.navigation.invoices') }}</AtomNavigationLink>
                                    </li>
                                    <li class="nav-item">
                                        <AtomNavigationLink class="nav-link" :to="{ name: 'orders-list' }">{{ $t('app.navigation.orders') }}</AtomNavigationLink>
                                    </li>
                                    <li class="nav-item dropdown" v-if="isPartner">
                                        <AtomNavigationLink class="nav-link" :to="{ name: 'partner' }">{{ $t('app.navigation.partners.title') }}</AtomNavigationLink>
                                    </li>
                                </ul>
                                <NotificationToggle/>
                                <AtomAvatar>
                                    <AtomDropdown style-variant="secondary" end :text="$cc.initials(name.firstname, name.surname)">
                                        <AtomDropdownItem :to="{name: 'profile-personal-data'}">
                                            {{ $t('app.navigation.profile.my_data') }}
                                        </AtomDropdownItem>
                                        <AtomDropdownItem class="customDesign">
                                            <ChangeCustomizationForm inMenu/>
                                        </AtomDropdownItem>
                                        <AtomDropdownItem class="danger" :to="{name: 'logout'}">
                                            {{ $t('app.navigation.profile.logout') }}
                                        </AtomDropdownItem>
                                    </AtomDropdown>
                                </AtomAvatar>
                            </div>
                        </template>
                    </div>
                </nav>
            </div>
        </div>
    </div>
</template>
<script>
    import { userStore, EventHandler } from '@development/cluster-center';
    import SwitchDesign from "@/components/SwitchDesign.vue";
    import BaseComponent from "@/components/BaseComponent.vue";
    import NotificationToggle from "@/components/Notification/NotificationToggle.vue";
    import ChangeCustomizationForm from './Accounting/Profile/Forms/ChangeCustomizationForm.vue';

    export default {
        extends: BaseComponent,
        name: 'TopBar',
        components: {SwitchDesign, NotificationToggle, ChangeCustomizationForm},
        data() {
            return {
                userStore: userStore(),
                selectedLanguage: [this.$i18n.locale],
                isManagedRoute: false
            };
        },
        mounted() {
            if (document.querySelector('.always-visible')) {
                document.querySelector('.sticky-top').style.height = document.querySelector('.always-visible').scrollHeight + 'px';
            }
            
            if (this.$route?.name && this.$route?.name.includes('managed-')) {
                this.isManagedRoute = true;
            }
        },
        computed: {
            isPartner() {
                return this.userStore.me.is_partner;
            },
            iaDarkMode() {
                return this.userStore.darkMode;
            },
            name() {
                if (
                    this.userStore &&
                    this.userStore.firstname &&
                    this.userStore.surname
                ) {
                    return {
                        firstname: this.userStore.firstname,
                        surname: this.userStore.surname
                    };
                }

                return { firstname: 'Max', surname: 'Cluster' };
            }
        },
        methods: {
            changeLocale(locale) {
                this.$i18n.locale = locale[0];
                this.$root.$i18n.locale = locale[0];
                this.selectedLanguage = locale;

                EventHandler.dispatch('app:updated:locale');
            },
            getInitials() {
                return window.initals(this.name.firstname, this.name.surname);
            }
        },
        props: {
            languages: {
                type: Array,
                default: () => []
            }
        },
        watch: {
            "$route.name"(val) {
                this.isManagedRoute = val.toLowerCase().includes('managed-');
            },
            selectedLanguage(val) {
                this.userStore.$patch({lang: val[0]});
            },
            '$i18n.locale'(locale) {
                this.selectedLanguage = [locale];
            },
            '$root.$i18n.locale'(locale) {
                this.selectedLanguage = [locale];
            },
            languages(val) {
                if (!Array.isArray(val)) {
                    return;
                }

                val.forEach(language => {
                    if (language.active) {
                        this.selectedLanguage = [language.name];
                    }
                });
            }
        }
    };
</script>
<style lang="scss">
    .lang-select .btn-group {
        .btn {
            text-transform: uppercase;
            padding: .5rem 1.5rem;
        }

        .btn-secondary.active {
            background: $secondary !important;

            &:focus,
            &:active {
                border-color: $secondary;
            }
        }
    }

    .customDesign {

        .dropdown-item {
            padding-top: 0 !important;
            padding-bottom: 0 !important;
        }
    }

    .customDesign:hover {
        background-color: $white !important;
        color: $black;
        cursor: default;

        button {
            background-color: $white !important;
            color: $black;
            cursor: default;

            p {
                color: $black;
            }
        }
    }



    .support-row {
        font-size: 14px;
        flex-wrap: nowrap;

        .col {
            flex: 0 0 auto;
            width: auto;
        }

        .atom-icon {
            font-size: 1.2rem;
            transform: translateY(4px);
            margin-right: .2rem;
        }

        a {
            text-decoration: none;
        }
    }

    #main-nav {
        .atom-avatar {
            .btn {
                color: $primary;
                border-color: $primary;
            }

            .atom-dropdown ul.dropdown-menu {
                max-height: 600px !important;
            }
        }

        .navbar-nav {
            margin-left: .5rem;

            .nav-item {
                margin-right: 1rem;

                a {
                    font-size: 18px;
                }
            }
        }
    }

    /* stylelint-disable color-no-hex */
    .nav-divider {
        background: #F0F0F0;
        border-color: #F0F0F0;
    }

    .scrolled-down {
        transform: translateY(-150%);
        transition: all .3s ease-in-out;
    }

    .scrolled-up {
        transform: translateY(0);
        transition: all .3s ease-in-out;
    }

    #content-area {
        transition: padding .3s ease-in-out;
    }

    .always-visible {
        z-index: 2;
        position:relative;
        background: $white;
    }

    .autohide {
        background: $white;
    }

    .support-notify {
        transform: translateY(2px);
        display: inline-block;
    }
</style>