<template>
    <div class="row" v-bind="$attrs">
        <div class="col-12 mb-3">
            <h4>{{ $t('profile.notifications.title') }}</h4>
        </div>
        <div class="col-6">
            <AtomCheckbox
                id="notification-toggle"
                v-model="selected"
                :options="options"
                name="notifications"
                switches
            />
        </div>
    </div>
    <div class="row"></div>
</template>
<script>
    import {UserService} from "@development/cluster-center";

    export default {
        name: "NotificationsData",
        data() {
            return {
                selected: [],
                watchSelect: false
            };
        },
        mounted() {
            this.getNotifications();
        },
        computed: {
            options() {
                return [
                    { text: this.$i18n.t('profile.notifications.option'), value: true },
                ];
            }
        },
        methods: {
            async getNotifications() {
                return await UserService.showTechnicalNotifications().then(response => {
                    this.selected = [response.data.subscribed];
                });
            },
            async changeSubscription() {
                return await UserService[this.selected.length === 1 ?'subscribeNotifications' : 'unsubscribeNotifications']();
            }
        },
        watch: {
            selected() {
                if (this.watchSelect) {
                    this.changeSubscription();
                }

                this.watchSelect = true;
            }
        }
    };
</script>
