export default {
    de: {
        title: 'Bestellungen',
        title_cluster: 'Cluster',
        title_domain: 'Domains',
        title_certificate: 'SSL-Zertifikate',
        order_domain: 'Domain bestellen',
        order_cert: 'Zertifikat bestellen',
        order_cluster: 'Cluster bestellen',
        downloadPDF: 'PDF herunterladen',
        downloadDCV: 'DCV herunterladen',
        downloadCertificate: 'Zertifikat herunterladen',
        viewInvoice: 'Rechnung ansehen',
        generalInfo: 'Allgemein',
        orderNumber: 'Bestellung',
        orderDate: 'Bestelldatum',
        inArchitecture: 'In Architektur enthalten',
        nvmeDatabase: 'NVMe Datenbank',
        architecture_and_sla: 'Architektur & SLA',
        architecture: 'Architektur',
        sla: 'SLA',
        select: 'Auswählen',
        selected: 'Ausgewählt',
        more_features: 'weitere Features',
        wizardPreSite: {
            title: 'Bestell-Weiterleitung',
            info: 'Der Bestellvorgang wird aktuell in das neue User Interface integriert, Bestellungen sind daher vorerst nur in der bestehenden Oberfläche möglich. Eine erneute Anmeldung kann notwendig sein.',
            button: 'Zum Bestellvorgang'
        },
        wizard: {
            excl_tax: '*Exkl. 19% MwSt.',
            next_step: 'Nächster Schritt',
            previous_step: 'Vorheriger Schritt',
            order_now: 'Jetzt bestellen',
            no_customers: 'Keine Kunden vorhanden.',
            thanks_for_order: 'Vielen Dank für Ihre Bestellung',
            to_overview: 'Zur Bestellübersicht',
            label_comment: 'Anmerkung zur Bestellung',
            please_check_your_order: 'Bitte prüfen Sie die Angaben zu Ihrer Bestellung:',
            cluster: {
                please_select_hardware_configuration: 'Konfiguration der Hardware und der vorinstallierten Software des Clusters:',
                dynamic_cc_price: 'Dynamisch je Server',
                order_success_message: 'Wir prüfen Ihre Bestellung <strong>{orderNumber}</strong> umgehend und setzen uns mit Ihnen in Verbindung.<br><br>Möchten Sie uns vorab kontaktieren, können Sie uns telefonisch unter <strong><a class="atom-text-link" href="tel:{phone}">{phone_formatted}</a></strong> oder per E-Mail an <strong><a href="mailto:{mail}" class="atom-text-link">{mail}</a></strong> erreichen.<br><br>Viele Grüße aus Paderborn<br>Ihr maxcluster-Team',
                title: 'Bestellung - Cluster',
                stepper: {
                    customers: 'Kundenauswahl',
                    architecture: 'Architektur',
                    managed_service: 'Managed Service',
                    configuration: 'Konfiguration',
                    order: 'Bestellung',
                },
                existing_customer: 'Bestehende Kunden',
                new_customer: 'Neuer Kunde',
                please_select_customer: 'Bitte wählen Sie den Kunden aus, für den Sie einen Cluster erwerben möchten:',
                customer_form: {
                    company: 'Unternehmen',
                    label_company: 'Unternehmen',
                    label_department: 'Abteilung',
                    label_street: 'Straße und Hausnummer',
                    label_address: 'Adresse',
                    label_zip_code: 'PLZ',
                    label_city: 'Stadt',
                    label_phone: 'Telefon',
                    label_country: 'Land',
                    invoice_data: 'Rechnungsdaten',
                    label_email_invoice: 'E-Mail-Adresse für Rechnungen',
                    label_vat_id: 'UStIdNr.',
                    label_reference_sign: 'Bezugszeichen',
                    payment_method: 'Zahlungsmethode',
                    vat_id_tooltip: 'Die UStIdNr. ist innerhalb der EU und außerhalb von Deutschland erforderlich.',
                    label_bank: 'Bank',
                    label_bic: 'BIC',
                    label_account_holder: 'Kontoinhaber',
                    label_iban: 'IBAN',
                    save_credit_card: 'Kreditkarte speichern'
                },
                architecture: {
                    please_select: 'Bitte wählen Sie die passende Cluster-Architektur aus:',
                    web_cluster: {
                        headline: 'Web Cluster',
                        subline: 'Hohe Ausfallsicherheit und maximale Performance mit zwei Servern.',
                        listing: {
                            scaling: 'Täglich ohne Unterbrechung skalierbar',
                            cpu: 'CPU: 1 bis 16',
                            ram: 'RAM: 2 bis 48 GB',
                            nvme_ssd: 'NVMe SSD: 25 bis 250 GB',
                            nvme_storage: 'NVMe Storage: 10 bis 500 GB',
                            traffic: 'Traffic: 5 TB',
                        }
                    },
                    extended_web_cluster: {
                        headline: 'Extended Web Cluster',
                        subline: 'Maximale Skalierbarkeit mit redundantem Anwendungs- und Datenbankserver.',
                        listing: {
                            scaling: 'Täglich ohne Unterbrechung skalierbar',
                            cpu: 'CPU: 2 bis 32',
                            ram: 'RAM: 4 bis 96 GB',
                            nvme_ssd: 'NVMe SSD: 50 bis 500 GB',
                            nvme_storage: 'NVMe Storage: 20 bis 500 GB',
                            traffic: 'Traffic: 5 TB',
                        }
                    },
                    cloud_web_cluster: {
                        headline: 'Cloud Web Cluster',
                        subline: 'Höchste Flexibilität und Individualität mit variabler Anzahl redundanter Anwendungs- und Datenbankserver.',
                        listing: {
                            scaling: 'Täglich ohne Unterbrechung skalierbar',
                            cpu: 'CPU: 4 bis 192',
                            ram: 'RAM: 4 bis 512 GB',
                            nvme_ssd: 'NVMe SSD: 50 bis 500 GB',
                            nvme_storage: 'NVMe Storage: 20 bis 500 GB',
                            traffic: 'Traffic: 5 TB',
                        }
                    }
                },
                managed_service: {
                    please_select: 'Bitte wählen Sie das passende Managed Service Paket aus:',
                    sla: 'Service Level Agreement',
                    listing: {
                        reaction_time: '<strong>{time} Min.</strong> Garantierte Reaktionszeit',
                        remote_hands:  '<strong>{time} Min.</strong> Remote Hands',
                        developer_support: 'Support für Entwickler:innen|<strong>{n} Min.</strong> Support für Entwickler:innen|<strong>{n} Min.</strong> Support für Entwickler:innen'
                    },
                    basic: {
                        headline: 'Managed Basic',
                        subline: 'Managed Basic Features',
                        listing: {
                            failure_protection: 'Automatischer Ausfallschutz',
                            external_firewall: 'Externe Firewall',
                            managed_dns: 'Managed DNS',
                            managed_email: 'Managed E-Mail Server'
                        }
                    },
                    business: {
                        headline: 'Managed Business',
                        subline: 'Managed Basic Features',
                        listing: {
                            performance_monitoring: 'Externe Performance-Messung',
                            proactive_monitoring: 'Proaktives Monitoring',
                            direct_contact_person: 'Feste Service-Ansprechpartner:innen',
                        }
                    },
                    enterprise: {
                        headline: 'Managed Enterprise',
                        subline: 'Managed Business Features',
                        listing: {
                            log_checks: 'Überwachung der Log-Dateien',
                            performance_scaling_per_month: 'Performance- und Skalierungsanalyse pro Monat',
                        }
                    }
                },
                configuration: {
                    please_select: 'Konfiguration der Hardware und der vorinstallierten Software des Clusters:',
                    hardware: {
                        headline: 'Hardware',
                        popover: '<p>Mit der individuellen Skalierung können Prozessoren, Arbeitsspeicher oder NVMe SSD unabhängig voneinander im laufenden Betrieb verändert werden. Die Skalierung wird innerhalb von Sekunden und ohne Unterbrechung der Anwendung und Dienste durchgeführt.</p><p><strong>Da die Abrechnung der Ressourcen täglich erfolgt, zahlen Sie nur die tatsächlich genutzten Ressourcen.</strong></p>',
                        cpu: 'CPU',
                        ram: 'RAM',
                        nvme_ssd: 'NVMe SSD',
                        nvme_storage: 'NVMe Storage',
                    },
                    software_and_extension: {
                        headline: 'Software & Erweiterungen',
                        popover: 'Durch die Auswahl der Anwendung werden bestimmte anwendungsspezifische Einstellungen hinterlegt und ggf. Softwarekomponenten vorinstalliert.',
                        software: 'Anwendung',
                        additional_ip_addresses: 'Zusätzliche IP-Adressen',
                        ip_addresses: '{count} IPv4 und {count} IPv6'
                    },
                    pricing: {
                        service: 'Leistung',
                        price_per_month: 'Preis/Monat',
                        base_configuration: 'Grundkonfiguration: {name}',
                        managed_service: 'Managed Service: {name}',
                        additional_cpu: 'Zusätzliche CPU',
                        additional_ram: 'Zusätzlicher RAM',
                        additional_nvme: 'Zusätzliche NVMe SSD',
                        additional_storage: 'Zusätzliche NVMe Storage',
                        additional_ip_addresses: 'Zusätzliche IP-Adressen',
                        total_sum: 'Gesamtpreis',
                    },
                }
            },
            domain: {
                title: 'Bestellung - Domain',
                please_select_customer: 'Bitte wählen Sie den Kunden aus, für den Sie eine Domain erwerben möchten:',
                please_select_cluster: 'Bitte wählen Sie den Cluster aus, für den Sie eine Domain erwerben möchten:',
                please_select_domain: 'Bitte wählen Sie die Domains aus, die sie erwerben möchten:',
                please_select_domain_owner: 'Wählen Sie einen den Domaininhaber und administrativer Ansprechpartner aus oder erstellen Sie neue Ansprechpartner:',
                add_to_order: 'hinzufügen',
                domain_label: 'Domain',
                domain_transfer: 'Domain Umzug',
                auth_code: 'Auth-Code',
                keep_nameserver: 'Nameserver beibehalten',
                handle_overview: 'Handle Übersicht',
                add_handle: 'Handle hinzufügen',
                domain_handle_label: 'Handle für Domaininhaber auswählen',
                admin_handle_label: 'Handle für ADMIN-C auswählen',
                table: {
                    domain: 'Domain',
                    costs_per_year: 'Preis/Jahr'
                },
                stepper: {
                    customer: 'Kunde',
                    cluster: 'Cluster',
                    domain: 'Domain',
                    handle: 'Handle',
                    order: 'Bestellung'
                },
                order_success_message: 'Die gewünschte(n) Domain(s) werden bestellt. Es wid nun eine externe Domain-Validierung durchgeführt damit die Richtlinien der Internet-Verwaltungsorganisation ICANN eingehalten werden.<br><br>Möchten Sie uns vorab kontaktieren, können Sie uns telefonisch unter <strong><a class="atom-text-link" href="tel:{phone}">{phone_formatted}</a></strong> oder per E-Mail an <strong><a href="mailto:{mail}" class="atom-text-link">{mail}</a></strong> erreichen.<br><br>Viele Grüße aus Paderborn<br>Ihr maxcluster-Team'
            },
            check_order: {
                please_check: 'Bitte prüfen Sie die Angaben zu Ihrer Bestellung:',
                customer_data: 'Kundendaten',
                label_message: 'Anmerkungen zur Bestellung',
                label_email_invoice: 'Rechnungen an E-Mail-Adresse',
                cpu: 'CPU: {n} Kerne|CPU: {n} Kern|CPU: {n} Kerne',
                ram: 'RAM: {n} GB',
                nvme: 'NVMe SSD: {n} GB',
                storage: 'NVMe Storage: {n} GB',
                network: 'IPv4 und IPv6: je {n} Adressen|IPv4 und IPv6: je {n} Adresse|IPv4 und IPv6: je {n} Adressen',
                payment_method: {
                    transfer: 'Überweisung',
                    credit_card: 'Kreditkarte',
                    creditcard: 'Kreditkarte',
                    debit: 'Lastschrift',
                },
                last_four_digits: 'Letzte 4 Ziffern',
                domain_owner: 'Domaininhaber ({handle})',
                admin_c: 'ADMIN-C ({handle})',
                contact_person: 'Ansprechpartner',
                company: 'Unternehmen',
                address: 'Adresse',
                telephone: 'Telefon',
                email: 'E-Mail-Adresse',
                domain: 'Domains|Domain|Domains',
                total_sum: 'Gesamtpreis',
            },
            customer_select: {
                number: 'Kundennummer',
                company: 'Unternehmen',
                location: 'Standort',
                contact_person: 'Ansprechpartner',
                payment_method: 'Zahlungsmethode',
                invoice_email: 'Rechnung per E-Mail'
            },
            cluster_select: {
                number: 'Cluster-Nummer',
                name: 'Cluster-Bezeichnung',
                architecture: 'Cluster-Architektur',
                no_clusters_for_customer: 'Es wurden keine Cluster mit einem aktiven Vertrag für diesen Kunden gefunden.'
            },
            certificate: {
                title: 'Bestellung - SSL-Zertifikat',
                stepper: {
                    customer: 'Kunde',
                    domain: 'Domain',
                    certificate: 'Zertifikat',
                    organization: 'Organisation',
                    order: 'Bestellung'
                },
                please_select_customer: 'Bitte wählen Sie den Kunden aus, für den Sie ein SSL-Zertifikat erwerben möchten:',
                please_select_domain: 'Bitte geben Sie die abzusichernde/n Domain/s an:',
                please_select_certificate: 'Bitte wählen Sie die Art des SSL-Zertifikats aus, welches Sie mit einer Laufzeit von 12 Monaten erwerben möchten:',
                please_select_owner: 'Bitte wählen Sie den Zertifikatsinhaber aus:',
                please_check_your_order: 'Bitte prüfen Sie die Angaben zu Ihrer Bestellung:',
                domain_selection: {
                    remove_domain: 'Domain entfernen',
                    delete: {
                        title: 'Domain entfernen',
                        description: 'Wollen Sie die Domain <strong>{domain}</strong> wirklich entfernen?',
                        success: 'Die Domain <strong>{domain}</strong> wurde erfolgreich entfernt.',
                    },
                    add: {
                        success: 'Die Domain <strong>{domain}</strong> wurde erfolgreich hinzugefügt.',
                    },
                    label: 'Domain'
                },
                certification: {
                    features: 'Features',
                    domain: {
                        single: {
                            headline: 'DomainSSL',
                            item_1: 'Innerhalb von Minuten ausgestellt',
                            item_2: 'Keine Papierdokumente nötig',
                            item_3: 'Validierung per HTTP, DNS oder E-Mail',
                            item_4: '12 Monate Laufzeit',
                            item_5: 'Verschlüsselung mit 4096 Bits',
                            description: 'Single Verifizierung Domain'
                        },
                        san: {
                            headline: 'Multi-DomainSSL',
                            item_1: 'Grundpreis für {domain} Abzusichernde Domains {priceFormatted}. Jede weitere Domain {sanPriceFormatted}.',
                            item_2: 'Innerhalb von wenigen Minuten ausgestellt',
                            item_3: 'Keine Papierdokumentation nötig',
                            item_4: 'Validierung per HTTP, DNS oder E-Mail',
                            item_5: '{duration} Monate Laufzeit',
                            description: 'Multi Domain Verifizierung'
                        },
                        wildcard: {
                            headline: 'WildcardSSL',
                            item_1: 'Alle Sub-Domains absichern',
                            item_2: 'Innerhalb von wenigen Minuten ausgestellt',
                            item_3: 'Keine Papierdokumentation nötig',
                            item_4: 'Validierung per DNS oder E-Mail',
                            item_5: '{duration} Monate Laufzeit',
                            description: 'Wildcard Sub-Domain Verifizierung'
                        }
                    },
                    organization: {
                        single: {
                            headline: 'OrganisationSSL',
                            item_1: 'Enthält authentifizierte Unternehmensdetails',
                            item_2: 'Validierung des Unternehmens durch Anruf',
                            item_3: 'Höheres Level an Vertrauen',
                            item_4: '{duration} Monate Laufzeit',
                            item_5: 'Verschlüsselung mit 4096 Bits',
                            description: 'Organisation Verifizierung'
                        },
                        wildcard: {
                            headline: 'OrganisationWildcardSSL',
                            item_1: 'Alle Eigenschaften des DomainSSL',
                            item_2: 'Alle Sub-Domains absichern',
                            item_3: 'Enthält authentifizierte Unternehmensdetails',
                            item_4: 'Validierung des Unternehmens durch Anruf',
                            item_5: 'Höheres Level an Vertrauen',
                            item_6: '{duration} Monate Laufzeit',
                            description: 'Organisation Wildcard Verifizierung'
                        }
                    },
                    extended: {
                        single: {
                            headline: 'ExtendedSSL',
                            item_1: 'Höchstes Level an Vertrauen für SSL',
                            item_2: 'Gestärktes Kundenvertrauen',
                            item_3: '{duration} Monate Laufzeit',
                            item_4: 'Verschlüsselung mit 4096 Bits',
                            description: 'Extended Domain Verifizierung'
                        },
                        san: {
                            headline: 'Multi-ExtendedSSL',
                            item_1: 'Alle Eigenschaften des DomainSSL und OrganisationSSL',
                            item_2: 'Bis zu {domain} Domains absichern',
                            item_3: 'Höchstes Level an Vertrauen für SSL',
                            item_4: 'Gestärktes Kundenvertrauen',
                            item_5: '{duration} Monate Laufzeit',
                            description: 'Extended Multi Domain Verifizierung',
                        }
                    },
                    duration: '{duration} Monate Laufzeit',
                },
                certificate_owner: {
                    edit_info: 'Bei Änderung wird mit Abschluss der Bestellung ein neuer Datensatz angelegt.',
                    title: 'Zertifikatsinhaber',
                    add_owner: 'Zertifikatsinhaber hinzufügen',
                    edit_owner: 'Zertifikatsinhaber bearbeiten',
                    company_title: 'Unternehmensdaten',
                    company: 'Unternehmen',
                    department: 'Abteilung',
                    duns: 'D-U-N-S (Data Universal Numbering System)',
                    telephone: 'Telefon',
                    email: 'E-Mail-Adresse',
                    street: 'Straße und Hausnummer',
                    zip: 'PLZ',
                    city: 'Ort',
                    federal_state: 'Bundesland',
                    country: 'Land',
                    administrator_title: 'Administrator',
                    firstname: 'Vorname',
                    surname: 'Nachname',
                    fax: 'Fax',
                    job_title: 'Berufsbezeichnung',
                    address: 'Adresse',
                    could_not_fetch: 'Es ist ein Fehler beim Abrufen der bestehenden Zertifikatsinhaber aufgetreten, bitte versuchen Sie es später erneut.',
                    edit_company: 'Unternehmen bearbeiten',
                    edit_admin: 'Administrator bearbeiten',
                    verification_method: 'Verifizierungsmethode auswählen',
                    domains_certificate: 'Domain(s) / Zertifikat',
                    verification_by: 'Verifizierung via:',
                    verification_by_email: 'Verifizierung via E-Mail:',
                    no_verification: 'Für die angegebene Domain <strong>{domain}</strong> existiert keine Verifizierungsmethode. Bitte prüfen Sie Ihre angeben oder kontaktieren Sie unseren Support unter <strong><a class="atom-text-link" href="tel:{phone}">{phone_formatted}</a></strong> oder per E-Mail an <strong><a class="atom-text-link" href="mailto:{mail}">{mail}</a></strong>',
                    no_email_verification: 'Für die angegebene Domain <strong>{domain}</strong> existiert keine Verifizierungsmethode via E-Mail.',
                },
                order_success_message: 'Möchten Sie uns kontaktieren, können Sie uns telefonisch unter <strong><a class="atom-text-link" href="tel:{phone}">{phone_formatted}</a></strong> oder per E-Mail an <strong><a class="atom-text-link" href="mailto:{mail}">{mail}</a></strong> erreichen.<br><br>Viele Grüße aus Paderborn<br>Ihr maxcluster-Team',
                csr_and_privatekey_download: 'CSR und Privatekey herunterladen',
                domain_order: 'Domain - {domain} ({order})',
                verification_by_mail_to: 'Verifizierung via E-Mail an:',
                verification_by: 'Verifizierung via:',
                intermediate_certificate: 'Zwischenzertifikat',
                intermediate_copy: 'Zwischenzertifikat kopieren',
                verification_http_https_txt_download_tooltip: 'Klicken Sie hier, um die notwendige Datei herunterzuladen.'
            }
        },
        addons: "Erweiterungen",
        csr: {
            title: "Registrierungsanfrage (CSR)",
            copy_button: "CSR Kopieren"
        },
        admin_contact: {
            title: "Administrativer Ansprechpartner",
            company: "Unternehmen",
            name: "Name",
            position: "Position",
            region: "Region",
            address: "Adresse",
            phone: "Telefon",
            fax: "Fax",
            email: "E-Mail"
        },
        quantity: "Anzahl",
        owner: {
            title: "Inhaber",
            company: "Unternehmen",
            department: "Abteilung",
            region: "Region",
            address: "Adresse",
            phone: "Telefon",
            email: "E-Mail"
        },
        additional_ip_addresses: "Zusätzliche IP-Adressen",
        hardwareBillingInfo: 'Die Abrechnung der Hardware erfolgt tagesgenau entsprechend der tatsächlichen Skalierung.',
        nvmeStorageBillingInfo: 'Die Abrechnung für den NVMe Storage erfolgt tagesgenau nach Bedarf in 5 GB-Schritten.',
        search_placeholder:{
            cluster: 'Bestellnummer, Cluster-Nummer, Kd. Nr., Kunde...',
            domain: 'Bestellnummer, Domain, Domaininhaber',
            certificate: 'Bestellnummer, Domain, Typ',
        },
        empty_table: 'Es sind keine Bestellungen vorhanden.',
        actions: {
            details: 'Bestelldetails',
            download_confirmation: 'Bestellbestätigung herunterladen',
            show_invoice: 'Rechnung ansehen',
            download_certificate: 'Zertifikat herunterladen',
            download_dcv: 'DCV herunterladen'
        },
        status: {
            accepted: 'Aktiv',
            open: 'Offen',
            completed: 'Abgeschlossen',
            declined: 'Abgelehnt',
            cancelled: 'Gekündigt',
            preparation_in_progress: 'In Bereitstellung',
            preparation_failed: 'Bereitstellung fehlgeschlagen',
            preparation_completed: 'Bereitstellung abgeschlossen',
            pending: 'Ausstehend',
        },
        table: {
            order_number: 'Bestellnummer',
            architecture: 'Architektur',
            sla: 'SLA',
            customer_number: 'Kd. Nr.',
            customer: 'Kunde',
            monthly_fee: 'Monatliche Gebühr',
            registration: 'Registrierung',
            order_date: 'Bestelldatum',
            domain: 'Domain',
            type: 'Typ',
            one_time_purchase: 'Einmalige Gebühr',
            runtime: 'Laufzeit',
            cluster_number: 'Cluster-Nr.',
            hardware: "Hardware",
            nvme_storage: "NVMe Storage",
            addons: "Erweiterungen",
            total: "Gesamt",
            regular_annual_fee: "Reguläre jährliche Grundgebühr",
            certificate: "Zertifikat",
            one_time_fee: "Einmalige Gebühr",
            verification: "Verifizierung",
            duration: "Laufzeit",
            authentication: "Authentifizierung",
            valid_until: "Gültig bis",
            basic_charge: 'Grundgebühr',
        },
    },
    en: {
        title: 'Orders',
        title_cluster: 'Cluster',
        title_domain: 'Domains',
        title_certificate: 'SSL-Certificate',
        order_domain: 'Order domain',
        order_cert: 'Order certificate',
        order_cluster: 'Order cluster',
        downloadPDF: 'Download PDF',
        downloadDCV: 'Download DCV',
        downloadCertificate: 'Download certificate',
        viewInvoice: 'View invoice',
        generalInfo: 'General',
        orderNumber: 'Order number',
        orderDate: 'Order date',
        architecture_and_sla: 'Architecture & SLA',
        architecture: 'Architecture',
        sla: 'SLA',
        select: 'Select',
        selected: 'Selected',
        more_features: 'more features',
        wizardPreSite: {
            title: 'Order-forwarding',
            info: 'The ordering process is currently being integrated into the new user interface, so orders can only be placed in the existing interface for the time being. It may be necessary to log in again.',
            button: 'To the order process'
        },
        wizard: {
            excl_tax: '*Excl. 19% VAT.',
            next_step: 'Next step',
            previous_step: 'Previous step',
            order_now: 'Order now',
            no_customers: 'No customers available.',
            thanks_for_order: 'Thank you for your order',
            to_overview: 'To the order overview',
            label_comment: 'Comment on the order',
            please_check_your_order: 'Please check the details of your order:',
            cluster_select: {
                number: 'Cluster number',
                name: 'Cluster name',
                architecture: 'Cluster architecture',
                no_clusters_for_customer: 'No clusters with an active contract were found for this customer.'
            },
            cluster: {
                please_select_hardware_configuration: 'Configuration of the hardware and the pre-installed software of the cluster:',
                dynamic_cc_price: 'Dynamic per server',
                order_success_message: 'We will check your order <strong>{orderNumber}</strong> immediately and will contact you.<br><br>If you would like to contact us in advance, you can reach us by phone at <strong><a class="atom-text-link" href="tel:{phone}">{phone_formatted}</a></strong> or by e-mail at <strong><a href="mailto:{mail}" class="atom-text-link">{mail}</a></strong>.<br><br>Best regards from Paderborn<br>Your maxcluster team',
                title: 'Order - Cluster',
                stepper: {
                    customers: 'Customer selection',
                    architecture: 'Architecture',
                    managed_service: 'Managed Service',
                    configuration: 'Configuration',
                    order: 'Order',
                },
                existing_customer: 'Existing customers',
                new_customer: 'New customer',
                please_select_customer: 'Please select the customer for whom you would like to purchase a cluster:',
                customer_form: {
                    company: 'Company',
                    label_company: 'Company',
                    label_department: 'Department',
                    label_street: 'Street and house number',
                    label_address: 'Address',
                    label_zip_code: 'ZIP code',
                    label_city: 'City',
                    label_phone: 'Phone',
                    label_country: 'Country',
                    invoice_data: 'Invoice data',
                    label_email_invoice: 'E-mail address for invoices',
                    label_vat_id: 'VAT ID',
                    label_reference_sign: 'Reference sign',
                    payment_method: 'Payment method',
                    vat_id_tooltip: 'The VAT ID is required within the EU and outside of Germany.',
                    label_bank: 'Bank',
                    label_bic: 'BIC',
                    label_account_holder: 'Account holder',
                    label_iban: 'IBAN',
                    save_credit_card: 'Save credit card'
                },
                architecture: {
                    please_select: 'Please select the appropriate cluster architecture:',
                    web_cluster: {
                        headline: 'Web Cluster',
                        subline: 'High availability and maximum performance with two servers.',
                        listing: {
                            scaling: 'Scalable daily without interruption',
                            cpu: 'CPU: 1 to 16',
                            ram: 'RAM: 2 to 48 GB',
                            nvme_ssd: 'NVMe SSD: 25 to 250 GB',
                            nvme_storage: 'NVMe Storage: 10 to 500 GB',
                            traffic: 'Traffic: 5 TB',
                        }
                    },
                    extended_web_cluster: {
                        headline: 'Extended Web Cluster',
                        subline: 'Maximum scalability with redundant application and database server.',
                        listing: {
                            scaling: 'Scalable daily without interruption',
                            cpu: 'CPU: 2 to 32',
                            ram: 'RAM: 4 to 96 GB',
                            nvme_ssd: 'NVMe SSD: 50 to 500 GB',
                            nvme_storage: 'NVMe Storage: 20 to 500 GB',
                            traffic: 'Traffic: 5 TB',
                        }
                    },
                    cloud_web_cluster: {
                        headline: 'Cloud Web Cluster',
                        subline: 'Maximum flexibility and individuality with a variable number of redundant application and database servers.',
                        listing: {
                            scaling: 'Scalable daily without interruption',
                            cpu: 'CPU: 4 to 192',
                            ram: 'RAM: 4 to 512 GB',
                            nvme_ssd: 'NVMe SSD: 50 to 500 GB',
                            nvme_storage: 'NVMe Storage: 20 to 500 GB',
                            traffic: 'Traffic: 5 TB',
                        }
                    }
                },
                managed_service: {
                    please_select: 'Please select the appropriate Managed Service package:',
                    sla: 'Service Level Agreement',
                    listing: {
                        reaction_time: '<strong>{time} Min.</strong> Guaranteed response time',
                        remote_hands:  '<strong>{time} Min.</strong> Remote Hands',
                        developer_support: 'Support for developers|<strong>{n} Min.</strong> Support for developers|<strong>{n} Min.</strong> Support for developers'
                    },
                    basic: {
                        headline: 'Managed Basic',
                        subline: 'Managed Basic Features',
                        listing: {
                            failure_protection: 'Automatic failure protection',
                            external_firewall: 'External firewall',
                            managed_dns: 'Managed DNS',
                            managed_email: 'Managed E-Mail Server'
                        }
                    },
                    business: {
                        headline: 'Managed Business',
                        subline: 'Managed Basic Features',
                        listing: {
                            performance_monitoring: 'External performance measurement',
                            proactive_monitoring: 'Proactive monitoring',
                            direct_contact_person: 'Fixed service contacts',
                        }
                    },
                    enterprise: {
                        headline: 'Managed Enterprise',
                        subline: 'Managed Business Features',
                        listing: {
                            log_checks: 'Monitoring of the log files',
                            performance_scaling_per_month: 'Performance and scaling analysis per month',
                        }
                    }
                },
                configuration: {
                    please_select: 'Configuration of the hardware and the pre-installed software of the cluster:',
                    hardware: {
                        headline: 'Hardware',
                        popover: '<p>With the individual scaling, processors, memory or NVMe SSD can be changed independently of each other during operation. The scaling is carried out within seconds and without interruption of the application and services.</p><p><strong>Since the resources are billed daily, you only pay for the resources actually used.</strong></p>',
                        cpu: 'CPU',
                        ram: 'RAM',
                        nvme_ssd: 'NVMe SSD',
                        nvme_storage: 'NVMe Storage',
                    },
                    software_and_extension: {
                        headline: 'Software & Extensions',
                        popover: 'By selecting the application, certain application-specific settings are stored and software components are possibly pre-installed.',
                        software: 'Application',
                        additional_ip_addresses: 'Additional IP addresses',
                        ip_addresses: '{count} IPv4 and {count} IPv6'
                    },
                    pricing: {
                        service: 'Service',
                        price_per_month: 'Price/month',
                        base_configuration: 'Base configuration: {name}',
                        managed_service: 'Managed Service: {name}',
                        additional_cpu: 'Additional CPU',
                        additional_ram: 'Additional RAM',
                        additional_nvme: 'Additional NVMe SSD',
                        additional_storage: 'Additional NVMe Storage',
                        additional_ip_addresses: 'Additional IP addresses',
                        total_sum: 'Total price',
                    },
                }
            },
            check_order: {
                please_check: 'Please check the details of your order:',
                customer_data: 'Customer data',
                label_message: 'Comments on the order',
                label_email_invoice: 'Invoices to e-mail address',
                cpu: 'CPU: {n} cores|CPU: {n} core|CPU: {n} cores',
                ram: 'RAM: {n} GB',
                nvme: 'NVMe SSD: {n} GB',
                storage: 'NVMe Storage: {n} GB',
                network: 'IPv4 and IPv6: {n} addresses each|IPv4 and IPv6: {n} address|IPv4 and IPv6: {n} addresses',
                payment_method: {
                    transfer: 'Transfer',
                    credit_card: 'Credit card',
                    creditcard: 'Credit card',
                    debit: 'Debit',
                },
                last_four_digits: 'Last 4 digits',
                domain_owner: 'Domain owner ({handle})',
                admin_c: 'ADMIN-C ({handle})',
                contact_person: 'Contact person',
                company: 'Company',
                address: 'Address',
                telephone: 'Telephone',
                email: 'E-mail address',
                domain: 'Domains|Domain|Domains',
                total_sum: 'Total price',
            },
            customer_select: {
                number: 'Customer number',
                company: 'Company',
                location: 'Location',
                contact_person: 'Contact person',
                payment_method: 'Payment method',
                invoice_email: 'Invoice by e-mail'
            },
            domain: {
                title: 'Order - Domain',
                please_select_customer: 'Please select the customer for whom you would like to purchase a domain:',
                please_select_cluster: 'Please select the cluster for which you would like to purchase a domain:',
                please_select_domain: 'Please select the domains you would like to purchase:',
                please_select_domain_owner: 'Select a domain owner and administrative contact or create new contacts:',
                add_to_order: 'add',
                domain_label: 'Domain',
                domain_transfer: 'Domain transfer',
                auth_code: 'Auth-Code',
                keep_nameserver: 'Keep nameserver',
                handle_overview: 'Handle overview',
                add_handle: 'Add handle',
                domain_handle_label: 'Select handle for domain owner',
                admin_handle_label: 'Select handle for ADMIN-C',
                table: {
                    domain: 'Domain',
                    costs_per_year: 'Price/year'
                },
                stepper: {
                    customer: 'Customer',
                    cluster: 'Cluster',
                    domain: 'Domain',
                    handle: 'Handle',
                    order: 'Order'
                },
                order_success_message: 'The desired domain(s) will be ordered. An external domain validation will now be carried out to comply with the guidelines of the Internet Administration Organization ICANN.<br><br>If you would like to contact us in advance, you can reach us by phone at <strong><a class="atom-text-link" href="tel:{phone}">{phone_formatted}</a></strong> or by e-mail at <strong><a href="mailto:{mail}" class="atom-text-link">{mail}</a></strong>.<br><br>Best regards from Paderborn<br>Your maxcluster team'
            },
            certificate: {
                title: 'Order - SSL Certificate',
                stepper: {
                    customer: 'Customer',
                    domain: 'Domain',
                    certificate: 'Certificate',
                    organization: 'Organization',
                    order: 'Order'
                },
                please_select_customer: 'Please select the customer for whom you would like to purchase an SSL certificate:',
                please_select_domain: 'Please select the domain(s) to be secured',
                please_select_certificate: 'Please select the type of SSL certificate you would like to purchase with a term of 12 months:',
                please_select_owner: 'Please select the certificate owner:',
                please_check_your_order: 'Please check the details of your order:',
                domain_selection: {
                    remove_domain: 'Remove domain',
                    delete: {
                        title: 'Remove domain',
                        description: 'Do you really want to remove the domain <strong>{domain}</strong>?',
                        success: 'The domain <strong>{domain}</strong> has been successfully removed.',
                    },
                    add: {
                        success: 'The domain <strong>{domain}</strong> has been successfully added.',
                    },
                    label: 'Domain'
                },
                certification: {
                    features: 'Features',
                    domain: {
                        single: {
                            headline: 'DomainSSL',
                            item_1: 'Issued within minutes',
                            item_2: 'No paper documents required',
                            item_3: 'Validation via HTTP, DNS or e-mail',
                            item_4: '12 months term',
                            item_5: 'Encryption with 4096 bits',
                            description: 'Single domain verification'
                        },
                        san: {
                            headline: 'Multi-DomainSSL',
                            item_1: 'Base price for {domain} domains to be secured {priceFormatted}. Each additional domain {sanPriceFormatted}.',
                            item_2: 'Issued within minutes',
                            item_3: 'No paper documentation required',
                            item_4: 'Validation via HTTP, DNS or e-mail',
                            item_5: '{duration} months term',
                            description: 'Multi domain verification'
                        },
                        wildcard: {
                            headline: 'WildcardSSL',
                            item_1: 'Secure all sub-domains',
                            item_2: 'Issued within minutes',
                            item_3: 'No paper documentation required',
                            item_4: 'Validation via DNS or e-mail',
                            item_5: '{duration} months term',
                            description: 'Wildcard sub-domain verification'
                        }
                    },
                    organization: {
                        single: {
                            headline: 'OrganizationSSL',
                            item_1: 'Contains authenticated company details',
                            item_2: 'Validation of the company by phone call',
                            item_3: 'Higher level of trust',
                            item_4: '{duration} months term',
                            item_5: 'Encryption with 4096 bits',
                            description: 'Organization verification'
                        },
                        wildcard: {
                            headline: 'OrganizationWildcardSSL',
                            item_1: 'All features of DomainSSL',
                            item_2: 'Secure all sub-domains',
                            item_3: 'Contains authenticated company details',
                            item_4: 'Validation of the company by phone call',
                            item_5: 'Higher level of trust',
                            item_6: '{duration} months term',
                            description: 'Organization Wildcard verification'
                        }
                    },
                    extended: {
                        single: {
                            headline: 'ExtendedSSL',
                            item_1: 'Highest level of trust for SSL',
                            item_2: 'Strengthened customer confidence',
                            item_3: '{duration} months term',
                            item_4: 'Encryption with 4096 bits',
                            description: 'Extended domain verification'
                        },
                        san: {
                            headline: 'Multi-ExtendedSSL',
                            item_1: 'All features of DomainSSL and OrganizationSSL',
                            item_2: 'Secure up to {domain} domains',
                            item_3: 'Highest level of trust for SSL',
                            item_4: 'Strengthened customer confidence',
                            item_5: '{duration} months term',
                            description: 'Extended multi domain verification',
                        }
                    },
                    duration: '{duration} months term',
                },
                certificate_owner: {
                    edit_info: 'When changing, a new record will be created upon completion of the order.',
                    title: 'Certificate owner',
                    add_owner: 'Add certificate owner',
                    edit_owner: 'Edit certificate owner',
                    company_title: 'Company data',
                    company: 'Company',
                    department: 'Department',
                    duns: 'D-U-N-S (Data Universal Numbering System)',
                    telephone: 'Telephone',
                    email: 'E-mail address',
                    street: 'Street and house number',
                    zip: 'ZIP code',
                    city: 'City',
                    federal_state: 'Federal state',
                    country: 'Country',
                    administrator_title: 'Administrator',
                    firstname: 'First name',
                    surname: 'Surname',
                    fax: 'Fax',
                    job_title: 'Job title',
                    address: 'Address',
                    could_not_fetch: 'An error occurred while retrieving the existing certificate owners, please try again later.',
                    edit_company: 'Edit company',
                    edit_admin: 'Edit administrator',
                    verification_method: 'Select verification method',
                    domains_certificate: 'Domain(s) / Certificate',
                    verification_by: 'Verification via:',
                    verification_by_email: 'Verification via e-mail:',
                    no_verification: 'There is no verification method for the specified domain <strong>{domain}</strong>. Please check your information or contact our support at <strong><a class="atom-text-link" href="tel:{phone}">{phone_formatted}</a></strong> or by e-mail at <strong><a class="atom-text-link" href="mailto:{mail}">{mail}</a></strong>',
                    no_email_verification: 'There is no verification method via e-mail for the specified domain <strong>{domain}</strong>.',
                },
                order_success_message: 'If you would like to contact us in advance, you can reach us by phone at <strong><a class="atom-text-link" href="tel:{phone}">{phone_formatted}</a></strong> or by e-mail at <strong><a href="mailto:{mail}" class="atom-text-link">{mail}</a></strong>.<br><br>Best regards from Paderborn<br>Your maxcluster team',
                csr_and_privatekey_download: 'Download CSR and private key',
                domain_order: 'Domain - {domain} ({order})',
                verification_by_mail_to: 'Verification via e-mail to:',
                verification_by: 'Verification via:',
                intermediate_certificate: 'Intermediate certificate',
                intermediate_copy: 'Copy intermediate certificate',
                verification_http_https_txt_download_tooltip: 'Click here to download the required file.'
            }
        },
        addons: "Add-ons",
        csr: {
            title: "Certificate Signing Request (CSR)",
            copy_button: "Copy CSR"
        },
        quantity: "Quantity",
        admin_contact: {
            title: "Administrative Contact",
            company: "Company",
            name: "Name",
            position: "Position",
            region: "Region",
            address: "Address",
            phone: "Phone",
            fax: "Fax",
            email: "Email"
        },
        additional_ip_addresses: "Additional IP addresses",
        hardwareBillingInfo: 'Hardware billing is done on a daily basis according to the actual scaling.',
        nvmeStorageBillingInfo: 'Billing for NVMe storage is done on a daily basis as needed in 5 GB increments.',
        inArchitecture: 'Included in architecture',
        nvmeDatabase: 'NVMe database',
        owner: {
            title: "Owner",
            company: "Company",
            department: "Department",
            region: "Region",
            address: "Address",
            phone: "Phone",
            email: "Email"
        },
        search_placeholder:{
            cluster: 'Order number, cluster number, customer number, customer...',
            domain: 'Order number, domain, domainholder',
            certificate: 'Order number, domain, type',
        },
        empty_table: 'There are no orders available.',
        actions: {
            details: 'Order details',
            download_confirmation: 'Download order confirmation',
            show_invoice: 'View invoice',
            download_certificate: 'Download certificate',
            download_dcv: 'Download DCV'
        },
        status: {
            accepted: 'Active',
            open: 'Open',
            completed: 'Completed',
            declined: 'Declined',
            cancelled: 'Cancelled',
            preparation_in_progress: 'In preparation',
            preparation_failed: 'Preparation failed',
            preparation_completed: 'Preparation completed',
            pending: 'Pending',
        },
        table: {
            order_number: 'Order number',
            architecture: 'Architecture',
            sla: 'SLA',
            customer_number: 'Customer number',
            customer: 'Customer',
            monthly_fee: 'Monthly fee',
            registration: 'Registration',
            order_date: 'Order date',
            domain: 'Domain',
            type: 'Type',
            one_time_purchase: 'One-time fee',
            runtime: 'Runtime',
            cluster_number: 'Cluster number',
            hardware: "Hardware",
            nvme_storage: "NVMe storage",
            addons: "Add-ons",
            total: "Total",
            regular_annual_fee: "Regular annual fee",
            certificate: "Certificate",
            one_time_fee: "One-time fee",
            verification: "Verification",
            duration: "Duration",
            authentication: "Authentication",
            valid_until: "Valid until",
            basic_charge: 'Basic charge',
        },
    }
};
