<template>
    <div class="partner-card mb-2 card newest-contracts">
        <div class="partner-card-header">
            <h5>{{ $t('partner.newest_contracts.title') }}</h5>
            <p>
                {{ $t('partner.newest_contracts.description') }}
            </p>
        </div>
        <MoleculeTable class="mb-0 partner-card-table table-layout-fixed">
            <AtomTablePart type="body">
                <AtomTableRow v-for="(acquisition, index) in orderedAcquisitions" :key="`partner-customers-row-${index}`">
                    <template v-if="acquisition">
                        <AtomTableCell>
                            <p class="text-truncate mb-0">
                                <strong class="text-truncate" v-tooltip.hover :title="`${acquisition.acquiree?.acquirable?.number} (${acquisition.acquiree?.acquirable?.customer?.number} · ${acquisition.acquiree?.acquirable?.customer?.company})`">
                                    {{ acquisition.acquiree?.acquirable?.number }}
                                    ({{ acquisition.acquiree?.acquirable?.customer?.number }} · {{ acquisition.acquiree?.acquirable?.customer?.company }})
                                </strong>
                            </p>
                            <small>
                                {{ $d(acquisition.start_date, 'partner') }}
                            </small>
                        </AtomTableCell>
                    </template>
                    <template v-else>
                        <AtomTableCell></AtomTableCell>
                    </template>
                </AtomTableRow>
            </AtomTablePart>
        </MoleculeTable>
    </div>
    <AtomButton :to="{name: 'partner-contracts', params: {partnerId: currentCustomer?.id || 0}}">
        {{ $t('partner.newest_contracts.show_all') }}
    </AtomButton>
</template>
<script setup>
    import {computed} from 'vue';

    const props = defineProps({
        acquisitions: {
            type: Array,
            default: () => []
        },
        currentYear: {
            type: Number,
            default: new Date().getFullYear()
        },
        currentCustomer: {
            type: Object,
            default: () => {}
        }
    });

    const orderedAcquisitions = computed(() => {
        let tmpAcquisitions = props.acquisitions;

        tmpAcquisitions = tmpAcquisitions.reverse().filter(acquisition => {
            return acquisition.acquiree.acquirable.acquirable_type === 'cluster_contract';
        });

        if (tmpAcquisitions.length > 6) {
            return tmpAcquisitions.slice(0, 6);
        }

        if (tmpAcquisitions.length < 6) {
            return tmpAcquisitions.concat(Array(6 - tmpAcquisitions.length).fill(null));
        }

        return tmpAcquisitions;
    });
</script>
<style>
    .newest-contracts {
        .table-layout-fixed table {
            table-layout: fixed;
        }
    }
</style>