import { defineStore } from 'pinia';

export const notificationStore = defineStore('notificationStore', {
    state: () => ({
        notificationList: [
            {
            id: 0,
            title: 'notifications.notifications.notification_center.titel',
            message: 'notifications.notifications.notification_center.text',
            date: '2024-06-05',
            isRead: false,
            link: ''
        },
        {
            id: 1,
            title: 'notifications.notifications.ui_customization.titel',
            message: 'notifications.notifications.ui_customization.text',
            date: '2024-05-16',
            image: {
                de: require('@/assets/images/notifications/custom_design_de.png'),
                en: require('@/assets/images/notifications/custom_design_en.png'),
            },
            isRead: false,
            link: 'profile-personal-data'
        },
        {
            id: 2,
            title: 'notifications.notifications.table_config.titel',
            message: 'notifications.notifications.table_config.text',
            date: '2024-06-05',
            image: {
                de: require('@/assets/images/notifications/table_config_de.png'),
                en: require('@/assets/images/notifications/table_config_en.png'),
            },
            isRead: false,
            link: 'invoices-overview'
        },
    ]
    }),
    getters: {
    },
    actions: {
    },
    persist: true
});