<template>
    <PartnerHeaderLine />
    <div class="row" v-bind="$attrs">
        <div class="col-12 mt-2 mb-2 relative-1">
            <h4>{{ $t('partner.contracts') }}</h4>
        </div>
        <MoleculeLoader size="medium" v-if="actionIsRunning" />
        <p v-else-if="acquisitions.length === 0">{{ $t('partner.no_contracts') }}</p>
        <div class="col-12 mt-2" v-else>
            <OrganismAutoSortableTable
                :data="acquisitions"
                :configuration="tableConfiguration"
                initial-sorting-key="contract_number"
                pagination
                :id="[`${userId}-partner-contracts-table`]"
                configurable
                :configure-reset-button-text="$t('helper.actions.reset')"
                :configure-title-text="$t('helper.labels.hide_and_show_columns')"
                :configure-tooltip-text="configureTooltipText"
                @updated:config="hiddenColumns = $event.hidden"
                class="mb-3"
            >
                <template #header-amount>
                    <span v-tooltip="$t('partner.newest_contracts.table.last_relevant_revenue_tooltip')">
                        {{ $t('partner.newest_contracts.table.last_relevant_revenue') }}
                        <AtomIcon class="opacity-100" name="info-line" />
                    </span>
                </template>
                <template #header-revenue>
                    <span v-tooltip="$t('partner.newest_contracts.table.last_relevant_commission_tooltip')">
                        {{ $t('partner.newest_contracts.table.last_relevant_commission') }}
                        <AtomIcon class="opacity-100" name="info-line" />
                    </span>
                </template>
                <template #column-contract_number="contract">{{ contract.acquiree.acquirable.number }}</template>
                <template #column-customer="contract">{{ contract.acquiree.acquirable.customer.number }} · {{ contract.acquiree.acquirable.customer.company }}</template>
                <template #column-start_date="contract">{{ $d(contract.start_date, 'short') }}</template>
                <template #column-end_date="contract">
                    <template v-if="contract.end_date">
                        {{ $d(contract.end_date, 'short') }}
                    </template>
                    <template v-else>-</template>
                </template>
                <template #column-amount="contract">{{ $n(getAmount(contract, 'amount'), 'currency') }}</template>
                <template #column-revenue="contract">{{ $n(getAmount(contract, 'revenue'), 'currency') }}</template>
                <template #column-status="contract">
                    <AtomBadge variant="success" size="sm" v-if="contract.status === 'active'">
                        {{ $t('partner.newest_contracts.status.active') }}
                    </AtomBadge>
                    <AtomBadge variant="danger" size="sm" v-else-if="contract.status === 'ended'">
                        {{ $t('partner.newest_contracts.status.ended') }}
                    </AtomBadge>
                </template>
            </OrganismAutoSortableTable>
        </div>
    </div>
</template>
<script>
    import PartnerService from "@/services/Api/Accounting/PartnerService";
    import {Logger, userStore, ResponseService} from "@development/cluster-center";
    import PartnerHeaderLine from "@/components/Accounting/Partner/PartnerHeaderLine.vue";
    import BaseComponent from "@/components/BaseComponent.vue";

    export default {
        extends: BaseComponent,
        name: 'PartnerContratcs',
        components: {PartnerHeaderLine},
        data() {
            return {
                acquisitions: [],
                partnerId: 0,
                search: null,
                hiddenColumns: [],
                user: null
            };
        },
        computed: {
            userId() {
                return this.user.id;
            },
            configureTooltipText() {
                return window.Config.get('i18n').tc('helper.labels.hide_columns', this.hiddenColumns.length);
            },
            tableConfiguration() {
                return [
                    {key: 'contract_number', title: this.$i18n.t('partner.newest_contracts.table.contract_number'), sortable: true, sortKey: 'acquiree.acquirable.number'},
                    {key: 'customer', title: this.$i18n.t('partner.newest_contracts.table.customer'), sortable: true, sortKey: 'acquiree.acquirable.customer.company'},
                    {key: 'start_date', title: this.$i18n.t('partner.newest_contracts.table.from'), sortable: true},
                    {key: 'end_date', title: this.$i18n.t('partner.newest_contracts.table.to'), sortable: true, sortVal: item => {
                        return item.end_date || '-';
                    }},
                    {key: 'amount', title: this.$i18n.t('partner.newest_contracts.table.last_relevant_revenue'), sortable: true, sortVal: item => {
                        let value = item.amount || 0;

                        if (value === 0) {
                            return value.toFixed(2);
                        }

                        return parseFloat(value);
                    }},
                    {key: 'revenue', title: this.$i18n.t('partner.newest_contracts.table.last_relevant_commission'), sortable: true, sortVal: item => {
                        let value = item.revenue || 0;

                        if (value === 0) {
                            return value.toFixed(2);
                        }

                        return parseFloat(value);
                    }},
                    {key: 'status', title: this.$i18n.t('partner.newest_contracts.table.status'), sortable: true},
                    {key: 'menu', title: '', width: 75}
                ];
            },
        },
        created() {
            this.customerId = this.$route.params?.customerId || 0;
        },
        mounted() {
            this.user = userStore();
            this.initialize();
        },
        methods: {
            initialize() {
                this.actionIsRunning = true;

                this.getCustomersData().then(async customers => {
                    let customer = {};

                    if (customers.length > 0) {
                        this.partnerId = customers[0].id;
                        customer = customers[0];
                    }

                    if (this.$route.params.partnerId) {
                        this.partnerId = this.$route.params.partnerId;
                        customer = customers.find(partner => partner.id === parseInt(this.$route.params.partnerId));
                    }

                    if (customer) {
                        let acquisitions = customer?.acquisitions.filter(acquisition => {
                            return acquisition.acquiree.acquirable.acquirable_type === 'cluster_contract';
                        });

                        await this.getProvisions({
                            customer_id: parseInt(customer.customer?.id || 0),
                            type: 'provision',
                            provisioned_invoice_items: 1,
                            sort_by: 'invoiced_at.desc',
                            status: 'cleared',
                            per_page: 1
                        }).then(response => {
                            if (Array.isArray(response) || !response) {
                                return;
                            }

                            let contractData = {};

                            response?.invoice_items.forEach(invoiceItem => {
                                let itemableId = invoiceItem.itemable_id;
                                let itemableType = invoiceItem.itemable_type;

                                if (!contractData[itemableId + itemableType]) {
                                    contractData[itemableId + itemableType] = {
                                        amount: 0,
                                        revenue: 0
                                    };
                                }

                                contractData[itemableId + itemableType].amount += invoiceItem.amount;
                            });

                            acquisitions.forEach((acquisition, index) => {
                                if (!contractData[acquisition.acquiree.acquirable.id + acquisition.acquiree.acquirable.acquirable_type]) {
                                    return;
                                }

                                acquisitions[index]['amount'] = contractData[acquisition.acquiree.acquirable.id + acquisition.acquiree.acquirable.acquirable_type].amount;
                                acquisitions[index]['revenue'] = contractData[acquisition.acquiree.acquirable.id + acquisition.acquiree.acquirable.acquirable_type].amount / 100 * (response?.percentage || 0);
                            });

                            this.acquisitions = acquisitions || [];
                        });
                    }

                    this.actionIsRunning = false;
                });
            },
            async getProvisions(params = {}) {
                if (Number.isNaN(this.partnerId)) {
                    this.partnerId = 0;

                    if (this.$route.params.partnerId) {
                        this.partnerId = this.$route.params.partnerId;
                    }
                }

                const i18n = window.Config.get('i18n');

                return await PartnerService.getProvisions(params, this.partnerId).then(response => {
                    if (Array.isArray(response)) {
                        if (response[2] !== 401) {
                            ResponseService.danger(i18n.t('errors.undefined', {phone: this.$cc.support.phone_formatted, email: this.$cc.support.email }));
                        }

                        return response;
                    }

                    return response.data;
                }).catch(e => {
                    Logger.log(e, {
                        'custom-error-information': {
                            component: 'PartnerContracts',
                            function: 'getProvisions'
                        }
                    });
                    return [];
                });
            },
            lastTransaction(provision) {
                return provision.transactions[provision.transactions.length - 1];
            },
            async getCustomersData() {
                const i18n = window.Config.get('i18n');

                return await PartnerService.getMine().then(response => {
                    if (Array.isArray(response)) {
                        if (response[2] !== 401) {
                            ResponseService.danger(i18n.t('errors.undefined', {phone: this.$cc.support.phone_formatted, email: this.$cc.support.email }));
                        }

                        return [];
                    }

                    return response.data;
                }).catch(e => {
                    Logger.log(e, {
                        'custom-error-information': {
                            component: 'PartnerContracts',
                            function: 'getCustomersData'
                        }
                    });
                });
            },
            getAmount(acquisition, key) {
                let value = 0;

                if (Object.prototype.hasOwnProperty.call(acquisition, key)) {
                    value = acquisition[key];
                }

                return value;
            }
        },
        watch: {
            '$route.params.partnerId': {
                handler(id) {
                    this.partnerId = parseInt(id);
                    this.initialize();
                }
            }
        }
    };
</script>