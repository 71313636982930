export const appConfiguration = {
    API_URL: process.env.VUE_APP_API_BASE_URL,
    support: {
        email: 'support@maxcluster.de',
        phone: '+495251414130',
        phone_formatted: '+49 5251 4141 30'
    },
    advice: {
        email: 'beratung@maxcluster.de',
        phone: '+4952514141350',
        phone_formatted: '+49 5251 4141 350'
    },
    backups: {
        lifetime: 14
    },
    bugsnag: {
        apiKey: process.env.VUE_APP_BUGSNAG_API_KEY || '',
        appVersion: process.env.VUE_APP_BUGSNAG_APP_VERSION || '',
        excludes: {
            statusCodes: [422, 404, 403, 401],
        },
        autoTrackSessions: false,
        collectUserIp: false
    },
    faye: {
        base_url: process.env.VUE_APP_FAYE_BASE_URL
    },
    auth: {
        logout_redirect: { name: 'login' }
    },
    braintree: {
        api_key: process.env.VUE_APP_BRAINTREE_PRIVATE_KEY || ''
    },
    alerts: {
        // in milliseconds
        autoclose: {
            short: 3500,
            long: 6000
        },
        autoclose_types: ['success', 'info']
    },
    customers: {
        dpa_process_active: true
    },
    maxcluster: {
        payment: {
            bank: 'Sparkasse Paderbporn',
            account_holder: 'maxcluster GmbH',
            bic: 'WELADE3LXXX',
            iban: 'DE03476501300001085711'
        }
    },
    domains: {
        max_length: 63
    },
    i18n: {
        warnHtmlInMessage: process.env.VUE_APP_I18N_WARN_HTML_IN_MESSAGE || 'off',
    },
    mail: {
        feedback: 'feedback@maxcluster.de'
    },
    hubspot: {
        region: 'eu1',
        portalId: '25327754',
        demo: {
            formIdDe: 'a244daea-9334-468c-a44d-e8368550cd24',
            formIdEn: 'ac13312d-2c5c-481d-ab94-724210ecf239'
        }
    },
    legacy_cookie: process.env.VUE_APP_LEGACY_COOKIE || 'app_center_legacy',
    display_price_changes: betweenDate('2024-07-01', '2024-09-01'),
};

/* eslint-disable-next-line */
function isDate(val) {
    const date = new Date(val);
    const today = new Date();

    return today >= date;
}


/* eslint-disable-next-line */
function betweenDate(from, to) {
    const fromDate = Date.parse(from);
    const toDate = Date.parse(to);
    const diffDate = Date.now();

    return (diffDate <= toDate && diffDate >= fromDate);
}
