<template>
    <div v-bind="$attrs" class="design-switch">
        <AtomButton @click.prevent="showModal = true" icon-right variant="secondary">
            {{ $t('app.switch_design.title') }}
            <template #button-right>
                <AtomIcon name="pop-out-line" />
            </template>
        </AtomButton>
        <MoleculeModal :close-text="$t('helper.actions.abort')" v-model="showModal" size="md">
            <template #modal-headline>{{ $t('app.switch_design.title') }}</template>
            <template #content>
                <p>{{ $t('app.switch_design.description') }}</p>
                <AtomTextLink href="mailto:feedback@maxcluster.de">feedback@maxcluster.de</AtomTextLink>
            </template>
            <template #actions>
                <AtomButton :href="url" @click="setCookie">
                    {{ $t('app.switch_design.switch') }}
                </AtomButton>
            </template>
        </MoleculeModal>
    </div>
</template>
<script>
    import { useCookies } from "vue3-cookies";

    export default {
        name: "SwitchDesign",
        data() {
            return {
                showModal: false,
                url: process.env.VUE_APP_BASE_URL,
            };
        },
        methods: {
            setCookie() {
                const { cookies } = useCookies();

                if (!cookies.get(this.$app.legacy_cookie)) {
                    cookies.set(this.$app.legacy_cookie, true);
                }
            }
        },
        computed: {
            checkboxOptions() {
                return [{text: window.Config.get('i18n').t('app.switch_design.check_label'), value: true}];
            }
        }
    };
</script>