export default {
    de: {
        actions: {
            request_new: 'Jetzt anfordern'
        }
    },
    en: {
        actions: {
            request_new: 'Request now'
        }
    }
};
