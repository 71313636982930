<template>
    <MoleculeForm @submit.prevent="submit">
        <div class="row">
            <div class="col-12 mb-3">
                <h4>{{ $t('profile.personal_data.contact_data') }}</h4>
            </div>
            <div class="col-2">
                <AtomSelect
                    :options="genderOptions"
                    v-model="gender"
                    label="label"
                    track-by="value"
                    searchable
                    close-on-select
                    :show-labels="false"
                    :allow-empty="false"
                    :placeholder="$t('profile.personal_data.gender_label')"
                    :label-text="$t('profile.personal_data.gender_label')"
                    :animated-label="false"
                />
            </div>
            <div class="col-9"></div>
            <div class="col-6 mt-4">
                <AtomInput :animated-label="false" required :label="$t('profile.personal_data.firstname')" v-model="form.firstname" :error="v$.form.firstname.$error">
                    <template #error-message>{{ $t(v$.form.firstname.$errors[0].$message) }}</template>
                </AtomInput>
            </div>
            <div class="col-6 mt-4">
                <AtomInput :animated-label="false" required :label="$t('profile.personal_data.surname')" v-model="form.surname" :error="v$.form.surname.$error">
                    <template #error-message>{{ $t(v$.form.surname.$errors[0].$message) }}</template>
                </AtomInput>
            </div>
            <div class="col-6 mt-4">
                <AtomInput :animated-label="false" :label="$t('profile.personal_data.phone')"  v-model="form.telephone" :error="v$.form.telephone.$error">
                    <template #error-message>{{ $t(v$.form.telephone.$errors[0].$message) }}</template>
                </AtomInput>
            </div>
            <div class="col-6 mt-4">
                <AtomInput :animated-label="false" :label="$t('profile.personal_data.mobile')" v-model="form.mobile" :error="v$.form.mobile.$error">
                    <template #error-message>{{ $t(v$.form.mobile.$errors[0].$message) }}</template>
                </AtomInput>
            </div>
            <div class="col-6 mt-4">
                <AtomInput :animated-label="false" :label="$t('profile.personal_data.company')" v-model="form.company" :error="v$.form.company.$error">
                    <template #error-message>{{ $t(v$.form.company.$errors[0].$message, {max: 255}) }}</template>
                </AtomInput>
            </div>
            <div class="col-6 mt-4">
                <AtomInput :animated-label="false" :label="$t('profile.personal_data.department')" v-model="form.department"></AtomInput>
            </div>
            <div class="col-6 mt-4">
                <AtomInput :animated-label="false" :label="$t('profile.personal_data.street_and_number')" v-model="form.street"></AtomInput>
            </div>
            <div class="col-6 mt-4">
                <div class="row">
                    <div class="col-4">
                        <AtomInput :animated-label="false" :label="$t('profile.personal_data.postcode')" class="mr-3" v-model="form.postcode" :error="v$.form.postcode.$error">
                            <template #error-message>{{ $t(v$.form.postcode.$errors[0].$message, {max: 255}) }}</template>
                        </AtomInput>
                    </div>
                    <div class="col-8">
                        <AtomInput :animated-label="false" :label="$t('profile.personal_data.city')" clas="w-100" v-model="form.city"></AtomInput>
                    </div>
                </div>
            </div>
            <div class="col-6 mt-4">
                <AtomSelect
                    class="d-inline-block"
                    :options="countryOptions"
                    v-model="country"
                    label="label"
                    track-by="value"
                    searchable
                    close-on-select
                    :show-labels="false"
                    :allow-empty="false"
                    :placeholder="$t('countries.de')"
                    :label-text="$t('profile.personal_data.country')"
                    :animated-label="false"
                >
                </AtomSelect>
            </div>
            <div class="col-6 mt-4">
                <AtomSelect
                    class="d-inline-block"
                    :selected="language"
                    :options="languageOptions"
                    v-model="language"
                    label="label"
                    track-by="value"
                    searchable
                    close-on-select
                    :allow-empty="false"
                    :show-labels="false"
                    :placeholder="$t('profile.personal_data.language')"
                    :label-text="$t('profile.personal_data.language')"
                    :animated-label="false"
                >
                </AtomSelect>
            </div>
            <div class="col-12 mt-4">
                <AtomButton type="submit" :disabled="actionIsRunning" :loading="actionIsRunning">
                    {{ $t('profile.personal_data.save_data') }}
                </AtomButton>
            </div>
        </div>
    </MoleculeForm>
</template>
<script>
    import BaseComponent from "@/components/BaseComponent";
    import {ResponseService, UserService, ErrorHandler} from "@development/cluster-center";
    import {required, maxLength, phone} from '@development/cluster-center/src/utils/validators';
    import {languages, countries} from "@development/cluster-center/src/assets/lang";

    export default {
        emits: ['updated:contact'],
        extends: BaseComponent,
        props: {
            user: Object
        },
        data() {
            return {
                vuelidateExternalResults: {
                    form: {
                        firstname: [],
                        surname: [],
                        telephone: [],
                        mobile: [],
                        company: [],
                        department: [],
                        street: [],
                        postcode: [],
                        city: [],
                        country: [],
                        language: []
                    },
                },
                form: {
                    firstname: null,
                    surname: null,
                    telephone: null,
                    mobile: null,
                    company: null,
                    department: null,
                    street: null,
                    postcode: null,
                    city: null,
                    country: null,
                    language: null
                },
                language: {value: 'de', label: this.$i18n.t('languages.de')},
                country: {value: 'de', label: this.$i18n.t('countries.de')},
                gender: {value: 'male', label: this.$i18n.t('profile.personal_data.male')},
            };
        },
        computed: {
            genderOptions() {
                return [
                    {value: 'male', label: this.$i18n.t('profile.personal_data.male')},
                    {value: 'female', label: this.$i18n.t('profile.personal_data.female')},
                ];
            },
            languageOptions() {
                let options = [];

                Object.keys(languages.de).forEach(l => {
                    options.push({label: this.$i18n.t(`languages.${l}`), value: l});
                });

                return options;
            },
            countryOptions() {
                let options = [];

                Object.keys(countries.de).forEach(c => {
                    options.push({label: this.$i18n.t(`countries.${c}`), value: c});
                });

                return options;
            }
        },
        mounted() {
            this.prepare();
        },
        methods: {
            prepare() {
                let user = this.user || {};

                if (window.isEmpty(user)) {
                    return;
                }

                this.form.firstname = user?.firstname || '';
                this.form.surname = user?.surname || '';
                this.form.telephone = user?.telephone || '';
                this.form.mobile = user?.mobile || '';
                this.form.street = user?.street || '';
                this.form.postcode = user?.postcode || '';
                this.form.city = user?.city || '';
                this.form.department = user?.department || '';
                this.form.company = user?.company || '';

                this.language = {value: user?.language || 'de', label: this.$i18n.t(`languages.${user?.language || 'de'}`)};
                this.country = {value: user?.country || 'de', label: this.$i18n.t(`countries.${user?.country || 'de'}`)};
                this.gender = {value: user?.gender || 'male', label: this.$i18n.t(`profile.personal_data.${user?.gender || 'male'}`)};
            },
            async submit() {
                await this.resetValidation();

                if (!await this.validate()) {
                    return;
                }

                this.actionIsRunning = true;

                return await UserService.updateUser(this.prepareParams()).then(response => {
                    this.actionIsRunning = false;

                    if (Array.isArray(response)) {
                        if (response[2] !== 422) {
                            return ResponseService.globalNotify(this.$i18n.t('errors.undefined', {phone: this.$cc.support.phone_formatted, email: this.$cc.support.email }), 'danger');
                        }

                        let errors = ErrorHandler.parseEngineErrors(response[0].response.data.errors);
                        return this.addExternalErrors(errors, this.vuelidateExternalResults.form);
                    }

                    let user = response.data;

                    this.$i18n.locale = user?.language || 'de';
                    this.$root.$i18n.locale = user?.language || 'de';

                    ResponseService.globalNotify(this.$i18n.t('profile.personal_data.update_success'), 'success');

                    this.language = {value: user.language, label: this.$i18n.t(`languages.${user.language}`)};
                    this.country = {value: user.country, label: this.$i18n.t(`countries.${user.country}`)};
                    this.gender = {value: user.gender, label: this.$i18n.t(`profile.personal_data.${user.gender}`)};

                    this.$emit('updated:contact', user);
                    this.resetValidation();
                });
            },
            prepareParams() {
                return {
                    ...this.form,
                    ...{
                        gender: this.gender.value,
                        language: this.language.value,
                        country: this.country.value
                    }
                };
            }
        },
        watch: {
            user() {
                this.prepare();
            },
            language(val) {
                this.form.language = val.value;
            },
            country(val) {
                this.form.country = val.value;
            },
            gender(val) {
                this.form.gender = val.value;
            }
        },
        validations() {
            return {
                form: {
                    firstname: {required},
                    surname: {required},
                    company: {maxLength: maxLength(255)},
                    postcode: {maxLength: maxLength(255)},
                    mobile: { phone },
                    telephone: { phone }
                }
            };
        }
    };
</script>